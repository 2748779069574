<template>
<div>
  <transition name='slide-fade'>
    <div v-if='showEditor' class='bg-gray-200 rounded py-2 mb-4'>
      <header class='relative'>
        <h1 class='h1 text-center'>Profile Editor</h1>
        <button class='absolute top-0 right-2 font-bold text-gray-400 rounded-full border-2 border-gray-300 h-8 w-8 text-xl'
                @click='showEditor = false'
                title='Hide Editor'>
          X
        </button>
      </header>
      <div class='md:flex md:flex-wrap md:justify-items-start p-2 space-y-8 lg:space-y-0'>
        <section class='w-full lg:w-1/3 p-2 space-y-4 lg:space-y-2'>
          <h2 class='h2'>My Bio</h2>
          <label class='label block mb-2'>Tell us about yourself.</label>
          <div class='flex items-center'>
            <label class='w-1/3'>Name</label>
            <input class='w-2/3 input' type='text' placeholder='Name' v-model='name' />
          </div>
          <div class='flex justify-between'>
            <label class='w-1/3'>Description</label>
            <textarea class='w-2/3 input h-28' type='text' placeholder='Description' v-model='description' />
          </div>
          <div class='flex items-center justify-between'>
            <span class='w-1/3 inline'>Profile Photo</span>
            <input class='w-2/3 inline-block input' type='file' @change='avatarChange' />
          </div>
          <div class='flex items-center'>
            <label class='w-1/3'>Title</label>
            <input class='w-2/3 input' type='text' placeholder='Title' v-model='title' />
          </div>
          <div class='flex items-center'>
            <label class='w-1/3'>Company</label>
            <input class='w-2/3 input' type='text' placeholder='Company' v-model='company' />
          </div>
          <div class='flex items-center justify-between'>
            <span class='w-1/3 inline'>Company Logo</span>
            <input class='w-2/3 inline-block input' type='file' @change='logoChange' />
          </div>
        </section>
        <section class='w-full lg:w-1/3 p-2 space-y-4 lg:space-y-2'>
          <h2 class='h2'>Contact Information</h2>
          <label class='label block mb-2'>Empty fields do NOT show on your public profile.</label>
          <div class='flex items-center'>
            <label class='w-1/3'>Phone Number</label>
            <input class='w-2/3 input' type='text' v-model='phone' />
          </div>
          <div class='flex items-center'>
            <label class='w-1/3'>Public Email</label>
            <input class='w-2/3 input' type='text' v-model='publicEmail' />
          </div>
          <div class='flex items-center'>
            <label class='w-1/3'>Twitter Handle</label>
            <input class='w-2/3 input' type='text' v-model='twitterHandle' />
          </div>
          <div class='flex items-center'>
            <label class='w-1/3'>Facebook Profile</label>
            <input class='w-2/3 input' type='text' v-model='facebookProfile' />
          </div>
          <div class='flex items-center'>
            <label class='w-1/3 pr-2'>Instagram Profile</label>
            <input class='w-2/3 input' type='text' v-model='instagramProfile' />
          </div>
          <div class='flex items-center'>
            <label class='w-1/3'>LinkedIn Profile</label>
            <input class='w-2/3 input' type='text' v-model='linkedInProfile' />
          </div>
          <div class='flex items-center'>
            <label class='w-1/3'>Custom URL</label>
            <input class='w-2/3 input' type='text' v-model='customUrl' />
          </div>
        </section>
        <section class='w-full p-4 lg:w-1/3 lg:mx-auto space-y-4 lg:space-y-2'>
          <h2 class='h2'>Company Offering</h2>
          <label class='label block mb-2'>Optionally add a promotional benefit, <br /> which is only available to card holders.</label>
          <div class='flex items-center'>
            <label class='w-1/3'>Title</label>
            <input class='w-2/3 input' type='text' v-model='offeringTitle' name='Offering Title' />
          </div>
          <div class='flex items-center'>
            <label class='w-1/3'>Description</label>
            <textarea class='w-2/3 input' rows='3' v-model='offeringDescription' name='Offering Title'/>
          </div>
        </section>
      </div>
      <section class='w-full md:mx-auto lg:w-1/3 p-4 space-y-4 lg:space-y-2'>
        <div class='space-y-4 lg:space-y-2'>
          <h2 class='h2'>Profile Settings</h2>
          <div class='flex items-center'>
            <label class='w-1/3'>
              Partner URL
              <button class='button dark pulse' title='Copy URL' @click='copyUrl'>
                <svg class='w-4' xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 115.77 122.88" style="enable-background:new 0 0 115.77 122.88" xml:space="preserve">
                  <g>
                    <path class="st0" d="M89.62,13.96v7.73h12.19h0.01v0.02c3.85,0.01,7.34,1.57,9.86,4.1c2.5,2.51,4.06,5.98,4.07,9.82h0.02v0.02 v73.27v0.01h-0.02c-0.01,3.84-1.57,7.33-4.1,9.86c-2.51,2.5-5.98,4.06-9.82,4.07v0.02h-0.02h-61.7H40.1v-0.02 c-3.84-0.01-7.34-1.57-9.86-4.1c-2.5-2.51-4.06-5.98-4.07-9.82h-0.02v-0.02V92.51H13.96h-0.01v-0.02c-3.84-0.01-7.34-1.57-9.86-4.1 c-2.5-2.51-4.06-5.98-4.07-9.82H0v-0.02V13.96v-0.01h0.02c0.01-3.85,1.58-7.34,4.1-9.86c2.51-2.5,5.98-4.06,9.82-4.07V0h0.02h61.7 h0.01v0.02c3.85,0.01,7.34,1.57,9.86,4.1c2.5,2.51,4.06,5.98,4.07,9.82h0.02V13.96L89.62,13.96z M79.04,21.69v-7.73v-0.02h0.02 c0-0.91-0.39-1.75-1.01-2.37c-0.61-0.61-1.46-1-2.37-1v0.02h-0.01h-61.7h-0.02v-0.02c-0.91,0-1.75,0.39-2.37,1.01 c-0.61,0.61-1,1.46-1,2.37h0.02v0.01v64.59v0.02h-0.02c0,0.91,0.39,1.75,1.01,2.37c0.61,0.61,1.46,1,2.37,1v-0.02h0.01h12.19V35.65 v-0.01h0.02c0.01-3.85,1.58-7.34,4.1-9.86c2.51-2.5,5.98-4.06,9.82-4.07v-0.02h0.02H79.04L79.04,21.69z M105.18,108.92V35.65v-0.02 h0.02c0-0.91-0.39-1.75-1.01-2.37c-0.61-0.61-1.46-1-2.37-1v0.02h-0.01h-61.7h-0.02v-0.02c-0.91,0-1.75,0.39-2.37,1.01 c-0.61,0.61-1,1.46-1,2.37h0.02v0.01v73.27v0.02h-0.02c0,0.91,0.39,1.75,1.01,2.37c0.61,0.61,1.46,1,2.37,1v-0.02h0.01h61.7h0.02 v0.02c0.91,0,1.75-0.39,2.37-1.01c0.61-0.61,1-1.46,1-2.37h-0.02V108.92L105.18,108.92z"/>
                  </g>
                </svg>
              </button>
            </label>
            <input class='w-2/3 input' type='text' v-model='slug' :disabled='!isAdmin' />
          </div>
          <label class='label block mb-2 text-center'>Your cards are keyed to your partner url</label>
          <div class='flex items-center justify-between pt-8 pb-4 lg:pt-4 lg:pb-0'>
            <button class='button danger' @click='reload'>
              Revert Changes
            </button>
            <button class='button success' @click='submit'>
              Save Profile
            </button>
          </div>
        </div>
        <rails-errors :errors='errors' class='text-center p-2'></rails-errors>
        <rails-flash class='text-center p-2'></rails-flash>
      </section>
    </div>
  </transition>
  <div class='flex justify-between'>
    <h2 class='text-secondary text-3xl text-center'>Preview</h2>
    <div class='flex items-center space-x-2'>
      <button v-if="!user.published" class='button success bg-green-100' @click='publish(true)'>
        Publish Online
      </button>
      <div v-else class='flex'>
        <inertia-link :href='`/${user.slug}`' class='text-2xl text-blue-500 hover:text-blue-400 hover:underline mr-2'>View</inertia-link>
        <button v-if="user.published" class='button danger bg-red-100' @click='publish(false)'>
          Hide Profile (Unpublish)
        </button>
      </div>
      <button v-if='!showEditor' class='font-bold bg-gray-200 rounded-full h-8 w-8' @click='showEditor = true' title='Show Editor'>
        <svg class='h-4 w-8' xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 121.48 122.88" style="enable-background:new 0 0 121.48 122.88" xml:space="preserve">
          <g><path class="st0" d="M96.84,2.22l22.42,22.42c2.96,2.96,2.96,7.8,0,10.76l-12.4,12.4L73.68,14.62l12.4-12.4 C89.04-0.74,93.88-0.74,96.84,2.22L96.84,2.22z M70.18,52.19L70.18,52.19l0,0.01c0.92,0.92,1.38,2.14,1.38,3.34 c0,1.2-0.46,2.41-1.38,3.34v0.01l-0.01,0.01L40.09,88.99l0,0h-0.01c-0.26,0.26-0.55,0.48-0.84,0.67h-0.01 c-0.3,0.19-0.61,0.34-0.93,0.45c-1.66,0.58-3.59,0.2-4.91-1.12h-0.01l0,0v-0.01c-0.26-0.26-0.48-0.55-0.67-0.84v-0.01 c-0.19-0.3-0.34-0.61-0.45-0.93c-0.58-1.66-0.2-3.59,1.11-4.91v-0.01l30.09-30.09l0,0h0.01c0.92-0.92,2.14-1.38,3.34-1.38 c1.2,0,2.41,0.46,3.34,1.38L70.18,52.19L70.18,52.19L70.18,52.19z M45.48,109.11c-8.98,2.78-17.95,5.55-26.93,8.33 C-2.55,123.97-2.46,128.32,3.3,108l9.07-32v0l-0.03-0.03L67.4,20.9l33.18,33.18l-55.07,55.07L45.48,109.11L45.48,109.11z M18.03,81.66l21.79,21.79c-5.9,1.82-11.8,3.64-17.69,5.45c-13.86,4.27-13.8,7.13-10.03-6.22L18.03,81.66L18.03,81.66z"/></g>
        </svg>
      </button>
    </div>
  </div>
  <partner-profile :user='previewUser' />
  <partner-offer :partner='previewUser' />
</div>
</template>

<script>
import PartnerProfile from '../components/partner-profile.vue'
import PartnerOffer from '../components/partner-offer.vue'
import RailsErrors from '../components/rails-errors.vue'
import RailsFlash from '../components/rails-flash.vue'

import CurrentUser from '../mixins/current-user.vue'

export default {
  components: {
    PartnerProfile,
    PartnerOffer,
    RailsErrors,
    RailsFlash,
  },
  mixins: [
    CurrentUser
  ],
  props: {
    user: {
      type: Object,
      required: true
    },
    errors: {
      type: Object,
      required: false,
    }
  },
  data() {
    return {
      showEditor: true,
      name: this.user.name,
      title: this.user.title,
      company: this.user.company,
      description: this.user.description,
      phone: this.user.phone,
      publicEmail: this.user.public_email,
      twitterHandle: this.user.twitter_handle,
      facebookProfile: this.user.facebook_profile,
      instagramProfile: this.user.instagram_profile,
      linkedInProfile: this.user.linked_in_profile,
      customUrl: this.user.custom_url,
      slug: this.user.slug,
      avatar: null,
      logo: null,
      tempAvatarFileUrl: null,
      tempLogoFileUrl: null,
      offeringId: this.user.offering_id,
      offeringTitle: this.user.offering_title,
      offeringDescription: this.user.offering_description,
    };
  },
  computed: {
    previewUser() {
      return {
        name: this.name,
        title: this.title,
        company: this.company,
        description: this.description,
        phone: this.phone,
        public_email: this.publicEmail,
        facebook_profile: this.facebookProfile,
        instagram_profile: this.instagramProfile,
        twitter_handle: this.twitterHandle,
        linked_in_profile: this.linkedInProfile,
        custom_url: this.customUrl,
        avatar_url: this.tempAvatarFileUrl || this.user.avatar_url,
        offering_title: this.offeringTitle,
        offering_description: this.offeringDescription,
        logo_url: this.tempLogoFileUrl || this.user.logo_url,
        offering: this.previewOffer,
      }
    },
    previewOffer() {
      return {
        title: this.offeringTitle,
        description: this.offeringDescription,
      }
    },
    updateUser() {
      let user = Object.assign({}, this.previewUser, {
        slug: this.slug,
        offering_attributes: {
          id: this.offeringId,
          title: this.offeringTitle,
          description: this.offeringDescription,
        }
      });

      delete user.avatar_url;
      delete user.logo_url;

      delete user.offering;
      delete user.offering_id;
      delete user.offering_title;
      delete user.offering_description;

      if(this.avatar) {
        user.avatar = this.avatar;
      }

      if(this.logo) {
        user.logo = this.logo;
      }

      return user;
    }
  },
  methods: {
    avatarChange(event) {
      const [file] = event.target.files
      if(file) {
        this.tempAvatarFileUrl = URL.createObjectURL(file);
        this.avatar = file;
      }
    },
    logoChange(event) {
      const [file] = event.target.files
      if(file) {
        this.tempLogoFileUrl = URL.createObjectURL(file);
        this.logo = file;
      }
    },
    copyUrl() {
      if(!navigator.clipboard) {
        alert('Unable to to copy! Usually this is due to an outdated browser.');
        return;
      }

      let text = `${window.location.origin}/${this.slug}`;
      navigator.clipboard.writeText(text)
        .then(() => {}, (error) => { console.log('Unable to copy text', error); })
    },
    publish(shouldPublish) {
      this.$inertia.put(`/partners/${this.user.id}`, { user: { published: shouldPublish } }, this.$CSRF());
    },
    submit() {
      this.$inertia.put(
        `/partners/${this.user.id}`,
         { user: this.updateUser },
        Object.assign(this.$CSRF(), {
          preserveState: "errors",
          preserveScroll: true
        })
      )
    },
    reload() {
      // TODO: figure out this.$inertia.reload({preserveState: false});
      window.location.reload();
    },
  },
}
</script>

<style scoped>
.slide-fade-enter-active {
  @apply transition ease-out duration-300;
}
.slide-fade-leave-active {
  @apply transition ease-in duration-300;
}
.slide-fade-enter, .slide-fade-leave-to {
  @apply transform -translate-y-8 scale-y-90;
  @apply opacity-0;
}

.pulse {
  transition: background 0.8s;
}

.pulse:active {
  @apply bg-green-400;
  transition: background 0s;
}
</style>