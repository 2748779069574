<template>
  <simple-section class='w-full lg:w-7/12 lg:mx-auto'>
    <simple-header class='mb-4'>Partner Benefit</simple-header>
    <table class='w-full'>
      <offering-row
        :partner='partner'
        :obscured='false'
        :default-open='true'
      />
    </table>
  </simple-section>
</template>

<script>
import OfferingRow from './offering-row.vue';
import SimpleSection from './simple-section.vue'
import SimpleHeader from './simple-header.vue'

export default {
  components: {
    OfferingRow,
    SimpleSection,
    SimpleHeader,
  },
  props: {
    partner: {
      type: Object,
      required: true
    }
  }
}
</script>

<style>
  .focused-row {
    -webkit-box-shadow: -10px 0px 13px -7px #000000,
                        10px 0px 13px -7px #000000,
                        inset 0px 0px 13px 11px #AA8C30;
    box-shadow: -10px 0px 13px -7px #000000,
                10px 0px 13px -7px #000000,
                inset 0px 0px 13px 11px #AA8C30;
  }
</style>