<template>
  <header>
    <h1 class="h1 text-center">
      <slot />
    </h1>
    <rails-flash class='my-2 mx-4 px-2 py-1 text-center' />
  </header>
</template>

<script>
import RailsFlash from '../components/rails-flash.vue';

export default {
  components: {
    RailsFlash
  },
}
</script>