<template>
  <div>
    <partner-profile :user='partner' />
    <simple-section class='w-full lg:w-7/12 lg:mx-auto'>
      <template v-if='isMember'>
        <h1 class='h1 text-center mb-8 lg:mb-2'>Member Benefits</h1>
        <benefits :obscured='false' :partners='partners' />
      </template>
      <div v-else>
        <div v-if='memberRegistered' class='flex flex-col items-center justify-center'>
          <inertia-link href="/login" class='mb-2'>
            <button class='button light'>Login</button>
          </inertia-link>
          <label class='lg:text-sm'>Log in to view exclusive member benefits.</label>
        </div>
        <div v-else class='flex flex-col items-center justify-center'>
          <inertia-link :href="registrationUrl" class='mb-2'>
            <button class='button light'>Register</button>
          </inertia-link>
          <label class='lg:text-sm'>Unlock exclusive member benefits.</label>
        </div>
        <benefits class='my-2' :partners='partners' @unlock='unlock'/>
      </div>
    </simple-section>
  </div>
</template>

<script>
import PartnerProfile from '../components/partner-profile.vue';
import Benefits from '../components/benefits.vue';
import SimpleSection from '../components/simple-section.vue';

export default {
  components: {
    PartnerProfile,
    Benefits,
    SimpleSection,
  },
  props: {
    partner: {
      type: Object,
      required: true
    },
    number: {
      type: String,
      required: true,
    },
    memberRegistered: {
      type: Boolean,
      required: true
    },
    isMember: {
      type: Boolean,
      required: true
    },
    partners: {
      type: Array,
      required: true
    }
  },
  computed: {
    registrationUrl() {
      return `/members/${this.number}/register`;
    },
    unlockUrl() {
      return this.memberRegistered ? '/login' : this.registrationUrl;
    },
  },
  methods: {
    unlock() {
      this.$inertia.visit(this.unlockUrl);
    }
  }
}
</script>