<template>
  <tr class='border border-dark text-gray-600'>
    <td class='w-52 p-4'>
      <img class='w-44 h-44 object-cover' :src='partnerUrl' />
    </td>
    <td class='flex flex-col h-auto lg:h-48 p-2 lg:p-4'>
      <h3 class='block text-center'>
        <label class='label font-normal'>From</label>
        <span class='font-bold lg:text-2xl'> {{partner.name}}</span>
        <span class='hidden lg:inline-block' v-if='partner.title'>{{partner.title}}</span>
      </h3>
      <p class='block lg:hidden text-center' v-if='partner.title'>{{partner.title}}</p>
      <hr class='border border-secondary'/>
      <div class='flex-grow flex flex-col justify-center'>
        <social-bar :user='partner' :class='socialBarClass' class='lg:flex lg:items-center lg:justify-around' />
      </div>
    </td>
  </tr>
</template>

<script>
import SocialBar from './social-bar.vue';

export default {
  components: {
    SocialBar
  },
  props: {
    partner: {
      type: Object,
      required: true,
    }
  },
  computed: {
    socialBarButtonCount() {
      let count = 0;
      [
        'facebook_profile',
        'instagram_profile',
        'linked_in_profile',
        'phone',
        'public_email',
        'twitter_handle',
        'custom_url',
      ].forEach(field => {
        if(this.partner[field]) count++;
      })
      return count;
    },
    socialBarClass() {
      if(this.socialBarButtonCount <= 5 ) {
        return 'flex items-center justify-around';
      }
      if(this.socialBarButtonCount == 6) {
        return 'grid grid-cols-3';
      }
      return 'grid grid-cols-4';
    },
    partnerUrl() {
      return this.partner.avatar_url || '/silhouette.png';
    },
  },
}
</script>