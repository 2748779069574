<template>
  <simple-section>
    <flash-header>Login</flash-header>
    <div class='p-4 flex flex-col space-y-8 lg:space-y-2'>
      <div class='flex items-center justify-center'>
        <input class='input w-full' type='text' placeholder='Email' v-model='email'/>
      </div>
      <div class='flex items-center justify-center'>
        <input class='input w-full' type='password' placeholder='Password' v-model='password' @keyup.enter='submit'/>
      </div>
      <div class='flex items-center justify-between pt-4'>
        <inertia-link href='/password/reset'>
          <button type='button' class='button dark'>
            Reset Password
          </button>
        </inertia-link>
        <button type='button' class='button success' @click='submit'>
          Login
        </button>
      </div>
    </div>
  </simple-section>
</template>

<script>
import SimpleSection from '../components/simple-section.vue';
import FlashHeader from '../components/flash-header.vue';

export default {
  components: {
    SimpleSection,
    FlashHeader,
  },
  data() {
    return {
      email: '',
      password: '',
    }
  },
  methods: {
    submit() {
      if(!(this.password && this.email)) return

      this.$inertia.post('/login', {
        user: {
          email: this.email,
          password: this.password,
        }
      }, this.$CSRF());
    }
  }
}
</script>