<template>
<div class='p-2 border border-gray-900 rounded-xl w-80 space-y-2 text-center'>
  <div>
    <h2 class='text-xl'>Assign Cards</h2>
    <label class='block italic text-gray-500'>Add card numbers below.<br />Only one card number per line.</label>
  </div>
  <div class='flex space-between text-sm'>
    <v-select class='bg-white w-full rounded'
              placeholder='Search Unassigned'
              v-model='selected'
              @search='fetchOptions'
              :filterable='false'
              :options='options'></v-select>
    <button type='button' class='button success ml-1' :disabled='!isSelected' @click='add'>
      Add
    </button>
  </div>
  <textarea class='block w-full py-1 px-2' rows='5' v-model='form.raw' placeholder="0000-0000-0000-0000">
  </textarea>
  <div class='flex items-center justify-between'>
    <button class='button dark' @click='clear'>
      Clear
    </button>
    <button class='button success' :disabled='!isValid' @click='assign'>
      Assign
      <span v-if='cards.length'>[{{cards.length}}]</span>
    </button>
  </div>
</div>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      form: this.$inertia.form({
        raw: '',
      }),
      selected: null,
      options: [],
    }
  },
  computed: {
    isSelected() {
      return this.selected;
    },
    isValid() {
      return this.cards.length > 0;
    },
    cards() {
      if(!this.form.raw) {
        return [];
      }
      return this.form.raw
        .trim()
        .split("\n")
        .map(r => r.trim())
        .filter(Boolean)
        .map(r => r.replace(/[^0-9]/g, ''));
    }
  },
  methods: {
    clear() {
      this.form.reset();
      this.selected = null;
    },
    add() {
      this.form.raw += this.selected + "\n";
      this.selected = null;
    },
    assign() {
      this.form.transform((data) => {
        return {
          ...data,
          cards: this.cards
        }
      })
      .post(`/${this.user.slug}/cards/assign`, Object.assign({}, this.$CSRF(), {
        onSuccess: () => this.form.reset(),
      }));
    },
    fetchOptions(search, loading) {
      loading(true)
      this.$axios.post('/cards/search/unassigned', { search }, this.$CSRF())
        .then(response => { this.options = response.data })
        .catch(error => console.log(error))
        .then(() => { loading(false)});
    }
  },
}
</script>