<template>
  <header>
    <h1 class="h1 text-center">
      <slot />
    </h1>
  </header>
</template>

<script>
export default {
}
</script>