<template>
  <div class='benefits-wrapper overflow-y-scroll pb-64'>
    <table class='w-full'>
      <offering-row
        v-for='partner in partners'
        :ref="'offeringRow_' + partner.slug"
        :key='partner.slug'
        :obscured='obscured'
        :partner='partner'
        @expanded='collapseOtherComponents'
        @unlock="$emit('unlock')"
      />
    </table>
  </div>
</template>

<script>
import OfferingRow from './offering-row.vue'

export default {
  components: {
    OfferingRow
  },
  props: {
    obscured: {
      type: Boolean,
      default: true
    },
    partners: {
      type: Array,
      required: true
    },
  },
  methods: {
    collapseOtherComponents(openedPartner) {
      this.partners.forEach(partner => {
        if(partner == openedPartner) {
          return;
        }
        let component = this.$refs[`offeringRow_${partner.slug}`][0];
        component.isExpanded = false;
      });
    }
  }
}
</script>

<style>
  .benefits-wrapper {
    height: 35vh;
  }

  .obscure {
    -webkit-filter: blur(7px);
    -moz-filter: blur(7px);
    -o-filter: blur(7px);
    -ms-filter: blur(7px);
    filter: blur(7px);
    /* force 3d accelleration */
    -webkit-transform: translate3d(0,0,0);
    -moz-transform: translate3d(0,0,0);
    -ms-transform: translate3d(0,0,0);
    -o-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
  }

  .focused-row {
    -webkit-box-shadow: -10px 0px 13px -7px #000000,
                        10px 0px 13px -7px #000000,
                        inset 0px 0px 13px 11px #AA8C30;
    box-shadow: -10px 0px 13px -7px #000000,
                10px 0px 13px -7px #000000,
                inset 0px 0px 13px 11px #AA8C30;
  }
</style>