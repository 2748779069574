<template>
  <tbody :class='{"focused-row": isExpanded}'>
    <tr ref='topRow' class='border border-dark' @click='toggleExpansion' @dblclick='toggleExpansion'>
      <td class='w-52 p-4'>
        <img class='w-44 h-44 object-cover' :src='logoUrl' />
      </td>
      <td class='p-2 lg:px-4 align-top text-gray-600 text-center'>
        <h3 class='font-bold block'>{{offerHeader}}</h3>
        <hr class='border border-secondary' />
        <template v-if='obscured'>
          <p v-if='hasOffer' class='font-bold'>{{offer.title}}</p>
          <p class='h-8 lg:h-auto overflow-ellipsis overflow-y-hidden obscure'>{{fakeDescription}}</p>
          <button class='button dark m-4' @click="$emit('unlock')">
            Redeem
          </button>
        </template>
        <template v-else>
          <template v-if='hasOffer'>
            <p v-if='offer.title' class='font-bold'>{{offer.title}}</p>
            <p>{{offer.description}}</p>
            <button v-if='!isExpanded' class='button dark' @click.stop='toggleExpansion'>
              Redeem
            </button>
          </template>
          <div v-else class='py-12'>
            <button v-if='!isExpanded' class='button dark' @click.stop='toggleExpansion'>
              Contact
            </button>
            <label v-else class='label'>Contact the partner below for available benefits.</label>
          </div>
        </template>
      </td>
    </tr>
    <partner-row v-if='isExpanded' :partner='partner'/>
  </tbody>
</template>

<script>
import PartnerRow from './partner-row.vue';
import CurrentUser from '../mixins/current-user.vue';

export default {
  components: { PartnerRow },
  mixins: [ CurrentUser ],
  props: {
    obscured: {
      type: Boolean,
      default: true,
    },
    partner: {
      type: Object,
      required: true
    },
    defaultOpen: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      isExpanded: false,
      letters: [
        ..."  AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz"
      ]
    }
  },
  computed: {
    offer() {
      return this.partner.offering;
    },
    hasOffer() {
      return !!this.offer;
    },
    blurClass() {
      return this.obscured ? 'obscure' : '';
    },
    offerHeader() {
      return this.partner.company || this.partner.name;
    },
    logoUrl() {
      return this.partner.logo_url || '/Digilux_Emblem2.svg';
    },
    fakeDescription() {
      let range = Math.floor(Math.random() * 200) + 30;
      let description = '';
      for(var i = 0; i < range; i++) {
        if(i % 5 == 0 || i % 8 == 0) { // LOL FRIZ BRUZ
          description += ' ';
        } else {
          description += this.randomLetter();
        }
      }
      return description;
    },
  },
  methods: {
    toggleExpansion() {
      if(this.obscured) {
        return;
      }

      this.isExpanded = !this.isExpanded

      if(this.isExpanded) {
        this.$emit('expanded', this.partner);
        this.$nextTick(() => {
          this.$refs['topRow'].scrollIntoView({behavior: "smooth"});
        })
      }
    },
    randomLetter() {
      return this.letters[Math.floor(Math.random()*this.letters.length)];
    },
  },
  mounted() {
    if(this.defaultOpen) {
      this.isExpanded = true
    }
  }
}
</script>