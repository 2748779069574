// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// require("@rails/ujs").start()
// require("@rails/activestorage").start()
// require("channels")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import Vue from 'vue'
import axios from 'axios'
import { App, plugin, Link } from '@inertiajs/inertia-vue'
import { InertiaProgress } from '@inertiajs/progress'
import Layout from '../components/layout.vue'
import vSelect from 'vue-select'

Vue.use(plugin)
Vue.component('v-select', vSelect)
Vue.component('inertia-link', Link);
InertiaProgress.init()

// Instead of using App.vue page, Inertia
// will use Rails application.html.erb layout page
const el = document.getElementById('app')

Vue.prototype.$CSRF = function () {
  let token;
  if (this.$page) {
    token = this.$page.props.csrf.token;
  } else {
    token = document.querySelector('meta[name="csrf-token"]').content
  }
  return {
    headers: {
      'X-CSRF-Token': token
    }
  }
}

Vue.prototype.$axios = axios; // notably not the instance inertia is using

new Vue({
  render: h => h(App, {
    props: {
      initialPage: JSON.parse(el.dataset.page),
      resolveComponent: (name) => {
        const page = require(`../pages/${name}`).default;
        page.layout = page.layout || Layout;
        return page;
      }
    },
  }),
}).$mount(el)