<script>
export default {
  computed: {
    currentUser() {
      return this.$page.props.current_user;
    },
    isLoggedIn() {
      return !!this.currentUser;
    },
    isAdmin() {
      return this.isLoggedIn && this.currentUser.isAdmin;
    },
    isPartner() {
      return this.isLoggedIn && this.$page.props.current_user.isPartner;
    },
  }
}
</script>