<template>
  <table class='table w-full'>
    <thead>
      <tr>
        <th>Name</th>
        <th>Email</th>
        <th>Company</th>
        <th>Profile URL</th>
        <th>Profile Published?</th>
        <th>Invite Link Sent?</th>
        <th>Cards</th>
        <th>Admin?</th>
        <th>&nbsp;</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for='user in users'>
        <td>{{user.name}}</td>
        <td>{{user.email}}</td>
        <td>{{user.company}}</td>
        <td>
          <inertia-link class='text-blue-500 hover:text-blue-400' :href='`/${user.slug}`'>{{user.slug}}</inertia-link>
        </td>
        <td>
          <div class='flex items-center justify-evenly'>
            {{user.published ? 'Yes' : 'No' }}
            <button v-if="user.published" class='button danger' @click='publishUser(user, false)'>
              Unpublish
            </button>
            <button v-else class='button success' @click='publishUser(user, true)'>
              Publish
            </button>
          </div>
        </td>
        <td>
          <div class='flex items-center justify-evenly'>
            {{user['invite_link_sent?'] ? 'Yes' : 'No'}}
            <button class='button dark' @click='inviteUser($event, user)'>
              Send Invite
            </button>
          </div>
        </td>
        <td class='text-center'>
          <inertia-link :href="`/${user.slug}/cards`" class='button dark'>
            Assign Cards
          </inertia-link>
        </td>
        <td class='text-center'>
          {{user.isAdmin ? 'Yes' : 'No' }}
        </td>
        <td class='text-right w-36'>
          <inertia-link :href="`/profile/${user.id}`">
            <button class='button dark'>
              Edit
            </button>
          </inertia-link>
          <button class='button danger' @click='deleteUser($event, user)'>
            Delete
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  data() {
    return {}
  },
  props: {
    users: {
      type: Array,
      default: [],
    }
  },
  methods: {
    publishUser(user, shouldPublish) {
      this.$inertia.put(`/users/${user.id}`, {
        user: {
          published: shouldPublish
        }
      }, this.$CSRF());
    },
    deleteUser(event, user) {
      event.target.blur();

      if(!confirm(`Are you sure you want to completely delete ${user.name}?`)) {
        return;
      }

      this.$inertia.delete(`/users/${user.id}`, this.$CSRF());
    },
    inviteUser(event, user) {
      event.target.blur();

      if(!confirm(`Are you sure you send an invite to ${user.email}?`)) {
        return;
      }

      this.$inertia.post(`/${user.slug}/invite`, {}, this.$CSRF());
    },
  }
}
</script>