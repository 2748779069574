<template>
  <transition name='fade'>
    <div v-if='hasFlash' class='italic rounded' :class='messageClass' @click='isDismissed = true'>
      <p>{{message}}</p>
    </div>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      isDismissed: false,
    }
  },
  computed: {
    messageClass() {
      if(this.alertMessage) {
        return 'bg-red-300';
      } else {
        return 'bg-green-300';
      }
    },
    hasFlash() {
      return !!this.message && !this.isDismissed;
    },
    message() {
      return this.alertMessage || this.successMessage;
    },
    successMessage() {
      return this.$page.props.flash.success;
    },
    alertMessage() {
      return this.$page.props.flash.alert;
    }
  },
  watch: {
    '$page.props.flash': function(newValue, oldValue) {
      this.isDismissed = false;
    }
  }
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>