<template>
  <div class='my-2 p-2 rounded-md border-2 border-green-800 text-center w-1/3 mx-auto'>
    <rails-errors :errors='errors'></rails-errors>
    <label class='italic block'>Add a new partner below, all fields required.</label>
    <div class='my-2 p-2'>
      <input ref='first' class='mb-2 p-2 block w-full' type='text' placeholder='Name' v-model='name' @input='generateUrl'/>
      <input class='mb-2 p-2 block w-full' type='text' placeholder='Email' v-model='email' />
      <div class='flex mb-2'>
        <p class='p-2'>https://www.digiluxgroup.com/</p>
        <input class='p-2 w-full' type='text' placeholder='url' v-model='slug' />
      </div>
    </div>
    <div class='flex justify-between'>
      <button class='button danger' @click='cancel'>
        Cancel
      </button>
      <button class='button success' :disabled='!isValid' @click='submit'>
        Create
      </button>
    </div>

  </div>
</template>

<script>
import RailsErrors from './rails-errors.vue'

export default {
  components: {
    RailsErrors
  },
  props: {
    errors: {
      type: Object,
      required: false,
    }
  },
  data() {
    return {
      name: '',
      email: '',
      slug: '',
    }
  },
  computed: {
    isValid() {
      return this.name && this.slug && this.email && this.email.indexOf('@') > -1;
    },
    user() {
      return {
        user: {
          name: this.name,
          email: this.email,
          slug: this.slug,
        }
      }
    }
  },
  methods: {
    generateUrl(event) {
      this.slug = this.name
        .toLowerCase()
        .replace(/\s+/g, '-')
        .replace(/[^0-9a-zA-Z\-]/g, '');
    },
    submit() {
      this.$inertia.post('/partners', this.user, this.$CSRF());
    },
    cancel() {
      this.$emit('cancel');
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs['first'].focus();
    })
  }
}
</script>