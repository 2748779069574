<template>
  <div class='flex flex-col min-h-screen'>
    <Nav />
    <div class='flex-grow'>
      <slot />
    </div>
    <Footer />
  </div>
</template>

<script>
import Nav from '../components/nav.vue'
import Footer from '../components/footer.vue'

export default {
  components: {
    Nav,
    Footer,
  },
}
</script>