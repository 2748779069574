<template>
<a :href='`tel:${number.replace(/[^0-9]/g, "")}`'>
  <span v-if='!(this.$slots.default)'>{{number}}</span>
  <slot></slot>
</a>
</template>

<script>
export default {
  props: {
    number: {
      type: String,
      required: true,
    }
  },
}
</script>