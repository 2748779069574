<template>
  <table class='h-4/5 w-full overflow-y-auto table'>
    <thead>
      <tr>
        <th>Card Number</th>
        <th>Assigned On</th>
        <th>Member Name</th>
        <th>Member Email</th>
        <th>Registered On</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for='card in cards'>
        <td>{{card.formatted_number}}</td>
        <td class='text-center'>{{card.friendly_assigned_on}}</td>
        <td>{{card.member_name}}</td>
        <td>{{card.member_email}}</td>
        <td class='text-center'>{{card.friendly_registered_on}}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    cards: {
      type: Array,
      required: true
    }
  }
}
</script>