<template>
  <simple-section>
    <h1 class="py-1 text-6xl lg:text-3xl text-secondary text-center">Register <br /> Member Access</h1>
    <form @submit.prevent='submit' class='my-4 space-y-8 lg:space-y-4'>
      <div class='flex items-center justify-center'>
        <label class='font-bold mr-2'>Member ID</label>
        <label>XXXX-XXXX-XXXX-{{last4}}</label>
      </div>
      <div class='flex items-center justify-between'>
        <label class='font-bold'>Name</label>
        <input type='text' class='input w-8/12' name='Name' v-model='form.name'>
      </div>
      <div class='flex items-center justify-between'>
        <label class='font-bold'>Email</label>
        <input type='text' class='input w-8/12' name='Email' v-model='form.email'>
      </div>
      <div class='flex items-center justify-between'>
        <label class='font-bold'>Password</label>
        <input type='password' class='input w-8/12' name='Password' v-model='form.password'>
      </div>
      <div class='flex items-center justify-between'>
        <label class='font-bold'>Confirm</label>
        <input type='password' class='input w-8/12' name='Confirm' v-model='form.passwordConfirmation'>
      </div>
      <div class='mt-4 flex items-center justify-center'>
        <label class='label'>To better locate member benefits in your area, please provide your address</label>
      </div>
      <div class='flex items-center justify-between'>
        <label class='font-bold'>Street</label>
        <input type='text' class='input w-8/12' name='Street' v-model='form.address_attributes.street'>
      </div>
      <div class='flex items-center justify-between'>
        <label class='font-bold'>City</label>
        <input type='text' class='input w-8/12' name='City' v-model='form.address_attributes.city'>
      </div>
      <div class='flex items-center justify-between'>
        <label class='font-bold'>State</label>
        <v-select class='bg-white input w-8/12'
          placeholder='Select'
          name='State'
          label='name'
          v-model='form.address_attributes.state'
          :reduce='option => option.abbreviation'
          :options='stateOptions'>
        </v-select>
      </div>
      <div class='flex items-center justify-between'>
        <label class='font-bold'>Zip</label>
        <input type='text' class='input w-8/12' name='Zip' v-model='form.address_attributes.zip'>
      </div>
      <div class='flex items-center justify-center'>
        <label class='label'>Additional Contact Options</label>
      </div>
      <div class='flex items-center justify-between'>
        <label class='font-bold'>Phone</label>
        <input type='text' class='input w-8/12' name='Phone' v-model='form.phone'>
      </div>
      <div class='flex items-center justify-center'>
        <button type='submit' class='button success' :disabled='!isValid'>Register</button>
      </div>
    </form>
    <rails-errors :errors='errors' class='text-center p-2' />
  </simple-section>
</template>

<script>
import RailsErrors from '../components/rails-errors.vue';
import SimpleSection from '../components/simple-section.vue';
import StatesMixin from '../mixins/states.vue';

export default {
  components: {
    SimpleSection,
    RailsErrors,
  },
  mixins: [ StatesMixin ],
  props: {
    number: {
      type: String,
      required: true,
    },
    errors: {
      type: Object,
      required: false,
    }
  },
  data() {
    return {
      form: {
        number: this.number,
        name: '',
        email: '',
        password: '',
        passwordConfirmation: '',
        phone: '',
        address_attributes: {
          street: '',
          city: '',
          state: '',
          zip: '',
        }
      }
    }
  },
  computed: {
    passwordsMatch() {
      return !!this.form.password && this.form.password == this.form.passwordConfirmation;
    },
    isValid() {
      return this.form.name &&
        this.form.email &&
        this.form.password &&
        this.form.passwordConfirmation &&
        this.passwordsMatch;
    },
    last4() {
      return this.number.substring(12, 16);
    },
  },
  methods: {
    submit() {
      this.$inertia.post('/members', this.form, this.$CSRF());
    }
  }
}
</script>