<template>
  <div class='my-2 p-2 rounded-md border-2 border-green-800 text-center w-1/3 mx-auto space-y-2'>
    <div class='flex items-center justify-evenly'>
      <label class='italic'>How many new Card Numbers would you like to generate?</label>
      <input class='p-2 w-16 text-right' type='text' placeholder='100' v-model='count' />
    </div>
    <div class='flex justify-between'>
      <button class='button danger' @click="$emit('cancel')">
        Cancel
      </button>
      <button class='button success' :disabled='!isValid' @click='submit'>
        Download
      </button>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      count: '',
    }
  },
  computed: {
    isValid() {
      return this.count && parseInt(this.count);
    },
  },
  methods: {
    submit() {
      let form = document.createElement('form');
      form.classList.add('hidden');
      form.setAttribute('method', 'post');
      form.setAttribute('action', '/cards/generate');

      let csrf = document.createElement('input');
      csrf.setAttribute('name', 'authenticity_token');
      csrf.setAttribute('value', this.$CSRF()['headers']['X-CSRF-Token']);
      form.appendChild(csrf);

      let count = document.createElement('input');
      count.setAttribute('name', 'count');
      count.setAttribute('value', this.count);
      form.appendChild(count);

      document.body.appendChild(form);
      form.submit();

      this.$emit('cancel');
    },
  },
}
</script>