<template>
<footer class="bg-gray-200 text-primary relative pt-1">
  <div class="container mx-auto px-6">
    <div class="flex flex-col items-center justify-center">
      <p>
        <span class="font-bold uppercase mt-4 md:mt-0 mb-2 border-b border-secondary">
          Contact Us
        </span>
      </p>
      <span class="my-1">
        <email-link address='info@digiluxgroup.com' subject='Hey DigiLux Group! May I have some more info?' class="text-blue-500 text-md hover:text-blue-400" />
      </span>
      <span class="my-1">
        <tel-link number="(713) 838-1929" class="text-blue-500 text-md hover:text-blue-400" />
      </span>
    </div>
  </div>
  <div class="container mx-auto px-6">
      <div class="mt-4 border-t-2 border-secondary flex flex-col items-center">
          <div class="sm:w-2/3 text-center py-6">
              <p class="font-bold">
                  © {{currentYear}} <span class='text-xs'>Powered by</span> Intuitive Systems
              </p>
          </div>
      </div>
  </div>
</footer>
</template>

<script>
import TelLink from './tel-link.vue';
import EmailLink from './email-link.vue';

export default {
  components: {
    TelLink,
    EmailLink,
  },
  computed: {
    currentYear() {
      return new Date().getFullYear();
    }
  }
}
</script>