<template>
  <div class="bg-gray-100 my-2 p-2">
    <header class='flex items-center justify-between mb-2'>
      <h1 class="text-2xl font-bold">Partners</h1>
      <div class='flex align-items justify-evenly gap-1'>
        <button v-if='!isGenerateExpanded' class='button success' @click='isGenerateExpanded = true'>Generate Card Numbers</button>
        <button v-if='!isNewExpanded' class='button success' @click='isNewExpanded = true'>Add Partner</button>
      </div>
    </header>
    <rails-flash />
    <new-partner v-if='isNewExpanded' :errors='errors' @cancel='isNewExpanded = false' />
    <generate-numbers v-if='isGenerateExpanded' @cancel='isGenerateExpanded = false' />
    <div class='overflow-x-auto w-full'>
      <partner-table :users='users' />
    </div>
  </div>
</template>

<script>
import PartnerTable from "../components/partner-table.vue"
import NewPartner from "../components/new-partner.vue"
import GenerateNumbers from "../components/generate-numbers.vue"
import RailsFlash from "../components/rails-flash.vue"

export default {
  components: {
    PartnerTable,
    NewPartner,
    GenerateNumbers,
    RailsFlash,
  },
  data() {
    return {
      isNewExpanded: false,
      isGenerateExpanded: false,
    }
  },
  props: {
    users: {
      type: Array,
      default: [],
    },
    errors: {
      type: Object,
      required: false,
    }
  }
}
</script>