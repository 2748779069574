<template>
  <div v-if='hasErrors' class='bg-red-300 italic rounded'>
    <p v-for='(messages, field) in errors'>
      {{field}}: {{messages.join(',')}}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    errors: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    }
  },
  data() {
    return {
    }
  },
  computed: {
    hasErrors() {
      return Object.keys(this.errors).length;
    }
  }
}
</script>