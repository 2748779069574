<template>
  <section class='bg-gray-200 w-full rounded-lg my-4 p-2'>
    <header class='text-center mb-2'>
      <h1 class='text-3xl'>Members</h1>
      <label class='block'>
        You have {{unregisteredCards.length}} unregistered cards.
      </label>
      <label v-if='!members.length' class='italic'>
        No registered members yet! Hand out your cards and ask members to scan and register thier account!
      </label>
    </header>
    <table v-if='members.length' class='table mx-auto'>
      <thead>
        <tr>
          <th>Name</th>
          <th>Card #</th>
          <th>Registered On</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for='member in members'>
          <td>{{member.name}}</td>
          <td>{{member.masked_number}}</td>
          <td class='text-center'>{{member.friendly_registered_on}}</td>
        </tr>
      </tbody>
    </table>
  </section>
</template>

<script>
export default {
  props: {
    members: {
      type: Array,
      required: true
    },
    unregisteredCards: {
      type: Array,
      required: true
    }
  }
}
</script>