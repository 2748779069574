<template>
<div class='bg-gray-200 rounded py-2 space-y-4'>
  <header class='text-center'>
    <h1 class='text-3xl font-bold'>Assigned Cards</h1>
    <h2 class='text-xl'>{{user.name}} - {{user.email}}</h2>
  </header>
  <section class='flex justify-center space-x-4'>
    <assign-cards :user='user' />
    <remove-cards :user='user' />
  </section>
  <rails-flash class='text-center p-2 w-1/3 mx-auto' />
  <div v-if='errors' class='bg-red-300 italic rounded w-1/3 mx-auto p-2'>
    <p v-for='error in errors'>{{error}}</p>
  </div>
  <section class='p-2'>
    <h2 class='inline text-xl font-bold pr-2'>Assigned Cards</h2>
    <label> {{assignedCardsCount}} assigned cards, {{registeredCardsCount}} registered members</label>
    <card-table :cards='cards' />
  </section>
</div>
</template>

<script>
import RailsFlash from '../components/rails-flash.vue'
import AssignCards from '../components/assign-cards.vue'
import RemoveCards from '../components/remove-cards.vue'
import CardTable from '../components/card-table.vue'

export default {
  components: {
    AssignCards,
    RemoveCards,
    RailsFlash,
    CardTable,
  },
  props: {
    user: {
      type: Object,
      required: true
    },
    cards: {
      type: Array,
      required: true
    },
    errors: {
      type: Array,
      required: false,
    }
  },
  computed: {
    assignedCardsCount() {
      return this.cards.length;
    },
    registeredCardsCount() {
      return this.cards.filter(c => !!c.friendly_registered_on).length;
    },
  },
}
</script>