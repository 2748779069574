<template>
  <simple-section>
    <flash-header>Update Password</flash-header>
    <div class='p-4 flex flex-col space-y-2'>
      <div class='flex items-center justify-center'>
        <input class='w-full px-2 py-1 rounded border border-color-gray-300' type='password' placeholder='Password' v-model='password'/>
      </div>
      <div class='flex items-center justify-center'>
        <input class='w-full px-2 py-1 rounded border border-color-gray-300' type='password' placeholder='Confirmation' v-model='passwordConfirmation' @keyup.enter='enter'/>
      </div>
      <div class='flex items-center justify-between pt-4'>
        <inertia-link href="/account">
          <button class='button danger' type='button'>
            Cancel
          </button>
        </inertia-link>
        <button class='button success' type='button' @click='submit' :disabled='!isValid'>
          Update
        </button>
      </div>
    </div>
  </simple-section>
</template>

<script>
import FlashHeader from '../components/flash-header.vue';
import SimpleSection from '../components/simple-section.vue';

export default {
  components: {
    FlashHeader,
    SimpleSection,
  },
  data() {
    return {
      password: '',
      passwordConfirmation: '',
    }
  },
  computed: {
    isValid() {
      return this.password &&
        this.password.length >= 6 &&
        this.password == this.passwordConfirmation;
    },
  },
  methods: {
    enter() {
      if(!this.isValid) {
        return;
      }

      this.submit();
    },
    submit() {
      this.$inertia.put(`/password`, {
        user: {
          password: this.password,
          password_confirmation: this.passwordConfirmation
        }
      }, this.$CSRF());
    }
  }
}
</script>