<template>
  <simple-section>
    <flash-header>Reset Password</flash-header>
    <div class='p-4 flex flex-col space-y-8'>
      <div class='flex items-center justify-center'>
        <input class='input w-full' type='text' placeholder='Email' v-model='email'/>
      </div>
      <div class='flex items-center justify-between'>
        <inertia-link href='/login'>
          <button type='button' class='button danger'>
            Cancel
          </button>
        </inertia-link>
        <button type='button' class='button success' @click='submit' :disabled='!isValid'>
          Reset
        </button>
      </div>
    </div>
  </simple-section>
</template>

<script>
import FlashHeader from '../components/flash-header.vue';
import SimpleSection from '../components/simple-section.vue';

export default {
  components: {
    FlashHeader,
    SimpleSection,
  },
  data() {
    return {
      email: '',
    }
  },
  computed: {
    isValid() {
      return this.email;
    }
  },
  methods: {
    submit() {
      this.$inertia.put('/password/reset', { email: this.email }, this.$CSRF());
    }
  }
}
</script>