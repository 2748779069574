<template>
  <partner-profile :user='user' />
</template>

<script>
import PartnerProfile from '../components/partner-profile.vue';

export default {
  components: {
    PartnerProfile,
  },
  props: {
    user: {
      type: Object,
      required: true
    }
  },
}
</script>