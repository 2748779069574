<template>
  <a rel='noopener' :href='forcedExternalHref'>
    <slot></slot>
  </a>
</template>

<script>
  export default {
    props: {
      href: {
        type: String,
        required: true,
      }
    },
    computed: {
      forcedExternalHref() {
        return `//${this.href}`;
      }
    }
  }
</script>