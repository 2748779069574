<template>
  <nav class='flex flex-wrap md:flex-nowrap items-center justify-between px-4 md:px-0 py-2 lg:py-1 mb-2 border-b-2 border-secondary'>
    <div class=''>
      <inertia-link href='/'>
        <img class='h-16 lg:h-10' src='/Digilux_Landscape.png' />
      </inertia-link>
    </div>
    <div class='mr-2 sm:mr-0'>
      <template v-if='isLoggedIn'>
        <inertia-link v-if='isAdmin' href='/partners' class='button light'>Manage <span class='hidden sm:inline-block'>Partners</span></inertia-link>
        <template v-if='isPartner'>
          <inertia-link href="/members" class='button light'>Members</inertia-link>
          <inertia-link href="/profile" class='button light'>Profile</inertia-link>
        </template>
        <inertia-link v-else :href='memberPath' class='button light'>Partner</inertia-link>
        <inertia-link href="/account" class='button light'>My Account</inertia-link>
        <a href='#' class='button light' @click='logout'>Logout</a>
      </template>
      <inertia-link v-else href='/login' class='button light'>Login</inertia-link>
    </div>
  </nav>
</template>

<script>
import CurrentUser from '../mixins/current-user.vue';

export default {
  data() {
    return {}
  },
  mixins: [
    CurrentUser,
  ],
  computed: {
    memberPath() {
      return this.$page.props.member_path;
    },
  },
  methods: {
    logout() { // this is a method because the destroy endpoint uses DELETE and CSRF
      this.$inertia.delete('/logout', this.$CSRF())
    }
  }
}
</script>