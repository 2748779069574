<template>
  <simple-section>
    <flash-header class='mb-8 lg:mb-4'>My Account</flash-header>
    <form @submit.prevent='submit' class='my-4 lg:my-0 space-y-8 lg:space-y-4'>
      <div class='flex items-center justify-between'>
        <label class='font-bold'>Name</label>
        <input class='input w-8/12' type='text' name='Name' v-model='form.name'>
      </div>
      <div class='flex items-center justify-between'>
        <label class='font-bold'>Email</label>
        <input class='input w-8/12' type='text' name='Email' v-model='form.email'>
      </div>
      <div class='mt-4 flex items-center justify-center'>
        <label class='label text-center'>To better locate member benefits in your area, please provide your address</label>
      </div>
      <div class='flex items-center justify-between'>
        <label class='font-bold'>Street</label>
        <input class='input w-8/12' type='text' name='Street' v-model='form.address_attributes.street'>
      </div>
      <div class='flex items-center justify-between'>
        <label class='font-bold'>City</label>
        <input class='input w-8/12' type='text' name='City' v-model='form.address_attributes.city'>
      </div>
      <div class='flex items-center justify-between'>
        <label class='font-bold'>State</label>
        <v-select class='bg-white input w-8/12'
          placeholder='Select'
          label='name'
          name='State'
          v-model='form.address_attributes.state'
          :reduce='option => option.abbreviation'
          :options='stateOptions'>
        </v-select>
      </div>
      <div class='flex items-center justify-between'>
        <label class='font-bold'>Zip</label>
        <input class='input w-8/12' type='text' name='Zip' v-model='form.address_attributes.zip'>
      </div>
      <div class='flex items-center justify-center'>
        <label class='label'>Additional Contact Options</label>
      </div>
      <div class='flex items-center justify-between'>
        <label class='font-bold'>Phone</label>
        <input type='text' class='input w-8/12' name='Phone' v-model='form.phone'>
      </div>
      <div class='flex items-center justify-between'>
        <inertia-link href='/password' class='button dark'>
          Reset Password
        </inertia-link>
        <button type='submit' class='button success' :disabled='!isValid'>Update</button>
      </div>
    </form>
  </simple-section>
</template>

<script>
import SimpleSection from '../components/simple-section.vue';
import FlashHeader from '../components/flash-header.vue';
import StatesMixin from '../mixins/states.vue';

export default {
  components: {
    SimpleSection,
    FlashHeader,
  },
  mixins: [ StatesMixin ],
  props: {
    user: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      form: {
        name: this.user.name,
        email: this.user.email,
        phone: this.user.phone,
        address_attributes: {
          id: this.user.address_id,
          street: this.user.address_street,
          city: this.user.address_city,
          state: this.user.address_state,
          zip: this.user.address_zip,
        }
      }
    }
  },
  computed: {
    isValid() {
      return this.form.name && this.form.email;
    },
  },
  methods: {
    submit() {
      this.$inertia.put(
        '/account',
        this.form,
        Object.assign(this.$CSRF(), {
          preserveState: false
        })
      );
    }
  }
}
</script>