<template>
<a :href='href'>
  <span v-if='!(this.$slots.default)'>{{address}}</span>
  <slot></slot>
</a>
</template>

<script>
export default {
  props: {
    address: {
      type: String,
      required: true,
    },
    subject: {
      type: String,
      required: false,
    }
  },
  computed: {
    href() {
      let raw = `mailto:${this.address}?`;

      if(this.subject) {
        raw += `subject=${this.subject}`;
      }

      return encodeURI(raw);
    }
  }
}
</script>