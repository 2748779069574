<template>
<div class="max-w-4xl flex items-center h-auto flex-wrap mx-auto my-16 bg-gray-200 text-gray-600 rounded-lg">
	<div id="profile" class="w-full lg:w-1/2 rounded-lg lg:rounded-l-lg lg:rounded-r-none shadow-2xl mx-0">
		<div class="lg:h-96 p-4 lg:p-10 text-center lg:text-left">
			<div class="block lg:hidden rounded-full shadow-xl mx-auto -mt-16 h-72 w-72 bg-cover bg-center bg-gray-200"
           :style="`background-image: url('${avatarUrl}')`">
      </div>
			<h1 class="h1 pt-4 lg:pt-0">{{user.name}}</h1>
			<div class="mx-auto lg:mx-0 w-4/5 pt-3 border-b-2 border-black-500 opacity-25"></div>
      <div class='flex flex-col justify-between lg:h-64'>
        <div>
          <p v-if='user.title' class="text-3xl lg:text-xl pt-2 font-bold flex items-center justify-center lg:justify-start">
            <svg class="h-8 lg:h-4 fill-current pr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <path d="M9 12H1v6a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-6h-16v2H9v-2zm0-1H0V5c0-1.1.9-2 2-2h4V2a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v1h4a2 2 0 0 1 2 2v6h-9V9H9v2zm3-8V2H8v1h4z"/>
            </svg>
            {{user.title}}
          </p>
          <p v-if='user.company' class="text-3xl lg:text-xl pt-2 font-bold flex items-center justify-center lg:justify-start">
            <svg class="h-8 lg:h-4 fill-current pr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <path d="M10 20a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm7.75-8a8.01 8.01 0 0 0 0-4h-3.82a28.81 28.81 0 0 1 0 4h3.82zm-.82 2h-3.22a14.44 14.44 0 0 1-.95 3.51A8.03 8.03 0 0 0 16.93 14zm-8.85-2h3.84a24.61 24.61 0 0 0 0-4H8.08a24.61 24.61 0 0 0 0 4zm.25 2c.41 2.4 1.13 4 1.67 4s1.26-1.6 1.67-4H8.33zm-6.08-2h3.82a28.81 28.81 0 0 1 0-4H2.25a8.01 8.01 0 0 0 0 4zm.82 2a8.03 8.03 0 0 0 4.17 3.51c-.42-.96-.74-2.16-.95-3.51H3.07zm13.86-8a8.03 8.03 0 0 0-4.17-3.51c.42.96.74 2.16.95 3.51h3.22zm-8.6 0h3.34c-.41-2.4-1.13-4-1.67-4S8.74 3.6 8.33 6zM3.07 6h3.22c.2-1.35.53-2.55.95-3.51A8.03 8.03 0 0 0 3.07 6z"/>
            </svg>
            {{user.company}}
          </p>
        </div>
        <div class='h-36 overflow-ellipsis overflow-hidden'>
          <p v-if='user.description' class="pt-4 text-2xl lg:text-sm" v-html='formattedDescription'></p>
        </div>
        <social-bar :user='user' class='mt-6 lg:pb-0 flex flex-wrap items-center justify-around space-x-4 lg:space-x-2' />
      </div>
		</div>
	</div>
  <div class="h-96 w-full lg:w-1/2 hidden lg:block">
    <img :src="avatarUrl" class="h-96 mx-auto rounded-none lg:rounded-r-lg hidden lg:block" :class='imgClass'>
  </div>
</div>
</template>

<script>
import SocialBar from './social-bar.vue';

export default {
  components: {
    SocialBar,
  },
  data() {
    return {}
  },
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  computed: {
    formattedDescription() {
      return this.user.description;
    },
    avatarUrl() {
      return this.user.avatar_url || '/silhouette.png';
    },
    imgClass() {
      return this.user.avatar_url ? "" : "p-8";
    }
  }
}
</script>